@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(-5vw);
        transform: translateX(-5vw);
    }

    100% {
        -webkit-transform: translateX();
        transform: translateX();
    }
}

@keyframes slide-right {
    0% {
        -webkit-transform: translateX(-5vw);
        transform: translateX(-5vw);
        opacity: 0;
    }

    25% {
        opacity: 0.3;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-left {
    0% {
        -webkit-transform: translateX(5vw);
        transform: translateX(5vw);
        opacity: 0;
    }

    25% {
        opacity: 0.3;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-left {
    0% {
        -webkit-transform: translateX(5vw);
        transform: translateX(5vw);
        opacity: 0;
    }

    25% {
        opacity: 0.3;
    }

    100% {
        -webkit-transform: translateX(5vw);
        transform: translateX(5vw);
        opacity: 1;
    }
}

@keyframes drawerOpen {
    100% {
        transform: translateY(-100%);
    }

    0% {
        transform: translateY(0);
    }
}

.slide-right {
    -webkit-animation: slide-right 1.2s cubic-bezier(.43, -0.02, .32, .95) both;
    animation: slide-right 1.2s cubic-bezier(.43, -0.02, .32, .95) both;
}

.slide-left {
    -webkit-animation: slide-left 1.2s cubic-bezier(.43, -0.02, .32, .95) both;
    animation: slide-left 1.2s cubic-bezier(.43, -0.02, .32, .95) both;
}

#root {
    overflow-x: auto;
}

#root>#loading,
#content>#loading,
#content>#loading {
    min-height: 100vh;
}

#root>#loading img {
    margin-top: 0px !important;
}

#content>div:has(> #loading) img {
    margin-top: 80px;
}

*,
*::before,
*::after {
    transition: all ease-in-out 0.1s;
    box-sizing: content-box;
    font-family: 'Montserrat';
    word-break: break-word;
}

*,
*::before,
*::after,
*:focus-visible {
    outline: none;
}

*:has(> #loading) {
    position: relative;
    min-height: 150px;
    margin-bottom: 70px
}

*:has(> #loading) img {
    object-fit: scale-down;
}

input#file-upload-button {
    display: none;
}

.swiper-slide {
    height: auto !important;
}

a {
    position: relative;
    color: #2C77A3;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

p {
    margin: 0px;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 1.28571rem;
    line-height: 1.5;
}

p:empty::before {
    content: ' ';
    white-space: pre;
}

@media screen and (max-width: 600px) {
    p {
        font-size: 17px;
    }
}

a:hover {
    color: #ffab2d;
}

.ReCaptha {
    display: inline-block;
    text-align: start;
}

@media screen and (max-width:600px) {
    .ReCaptha {
        scale: 0.8;
    }
}

img {
    width: 100%;
    object-fit: contain;
}

@media screen and (max-width:600px) {
    img.client-logo {
        width: 103%;
        height: auto;
    }
}

body {
    padding: 0px !important;
}

.text-icon {
    display: flex !important;
    align-items: center;
}

.MuiBox-root {
    margin: 125px 0px;
    overflow-x: clip;
}

@media screen and (max-width: 600px) {
    .MuiBox-root {
        margin: 25px 0px;
    }
}

.submenu ul {
    min-width: 200px !important;
}

.backdrop-visible .Mui-Backdrop-root {
    opacity: 0.7 !important;
    background: 'linear-gradient(238deg, #3ab6ff -7.16%, #b1737a 75.28%, #ffb038 138.04%)';
}

.submenu .logo {
    height: 32px;
    margin-right: 8px;
    margin-bottom: 5px;
}

.card-gradient-hover:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(75.08deg, #2C77A3 4.62%, #3AB6FF 51.22%, #A4DDFF 95.52%);
    transform: translate3d(0px, 0px, 0) scale(1.01);
    filter: blur(0px);
    transition: opacity 0.5s;
    border-radius: inherit;
    opacity: 0;
    filter: drop-shadow(0 0 0.3rem #3AB6FF);
}

.card-gradient-hover:hover::before {
    opacity: 1;
}

.card-gradient-hover:hover::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
}



::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
    transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar:hover {
    transition: all 0.5s ease-in-out;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgb(44, 119, 163)), color-stop(0.22, rgb(58, 182, 255)), color-stop(0.86, rgb(164, 221, 255)));
}